import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/system/staff',
    component: Layout,
    redirect: '/system/staff/specialist/index',
    meta: {
      title: '人员管理',
      keepAlive: true,
      requiresAuth: true
    },
    children: [
      {
        path: 'specialist/index',
        name: 'SystemStaffSpecialistIndex',
        component: () => import('@/views/system/staff/specialist/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '专家管理'
        }
      },
      {
        path: 'userAgent/index',
        name: 'SystemStaffUserAgentIndex',
        component: () => import('@/views/system/staff/userAgent/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '业务人员管理'
        }
      },
      {
        path: 'stats/index',
        name: 'SystemStaffStatsIndex',
        component: () => import('@/views/system/staff/stats/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: true,
          title: '开发人员统计'
        }
      }
    ]
  }
]

export default router
